<template>
  <layout-stepper>
    <template #body>
      <b-link @click="$router.push({name: 'step_deals'})">
        <feather-icon icon="ChevronLeftIcon"></feather-icon>
        Previous Step
      </b-link>
      <div class="d-flex justify-content-center">
        <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
          <b-col class="col-12 text-center">
            <h1 class="mb-1">Give them a sample</h1>
            <p>
              This is your chance to make a great first impression. Use high quality images or videos of your best work (no metrics or promotional content, please).
            </p>

            <b-form-file 
              v-model="files" 
              accept=".jpeg, .jpg, .png, .mp4, .mov"
              browse-text="Buscar" 
              class="my-2" 
              multiple
              @change="hasChangeFiles"
              :disabled="previews.length >= 10"
            >
            </b-form-file>

            <draggable v-model="previews"> 
              <b-col v-for="(preview, index) in previews" :key="index" class="col-12 p-0 py-2 border-bottom d-flex justify-content-between box-previews-draggable">
                <div class="d-flex align-items-center">
                  <b-icon icon="grip-vertical" class="grip-vertical-icon-preview"></b-icon>
                  <b-avatar :src="preview.preview" square class="avatar-preview-sample"></b-avatar>
                  <strong v-if="!preview.uuid">{{utils.shortText(5, preview.name)}} <span v-if="preview.name.split(' ').length > 6">...</span></strong>
                  <strong v-else class="text-muted">Image uploaded</strong>
                </div>
                <b-button variant="flat-secondary" @click="deleteFromDraggable(preview)">
                  <feather-icon icon="TrashIcon" size="20"></feather-icon>
                </b-button>
              </b-col>
            </draggable>
            <b-col class="col-12 d-flex justify-content-center flex-wrap">
              <strong class="text-danger col-12 mt-2 h4" v-if="previews.length > 10">You exceed the limit of files</strong>
              <b-button 
                variant="primary" 
                class="my-2" 
                :disabled="previews.length === 0 || previews.length > 10"
                @click="save()"
              >
                Save and next Step
              </b-button>
              <p class="f-11">At least one sample is required. Hold and drag your photos to change their order. Maximum of 10.</p>
            </b-col>
          </b-col>
        </b-row>
      </div>
    </template>
  </layout-stepper>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BLink,
  BFormFile,
  BAvatar,
} from 'bootstrap-vue';
import layoutStepper from '@/views/components/creator/layoutStepper.vue';
import { getThumbnails } from 'video-metadata-thumbnails';
import draggable from 'vuedraggable';
import profile_services from '@/services/profile';
import utils from '@/libs/utils';
import creator_services from '@/services/creator';

export default {
  name: 'samples',
  components: {
    BRow,
    BCol,
    BButton,
    BLink,
    BFormFile,
    BAvatar,
    layoutStepper,
    draggable,
  },
  data() {
    return {
      utils,
      files: [],
      previews: [],
      network_selected: null,
      steps: {},
      images_strings: []
    }
  },
  created() {
    this.getData();
  },
  methods: {
    hasChangeFiles() {
      const urls_blobs = [];
      setTimeout(() => {
        this.files.forEach((element) => {
          const url_blob = URL.createObjectURL(element);
          if (!element.type.includes('video')) {
            urls_blobs.push({preview: url_blob, name: element.name});
          } else {
            const thumbnail = getThumbnails(url_blob, {
              quality: 0.6
            });
            thumbnail.then((value) => {
              const url_blob_thubnail = URL.createObjectURL(value[0].blob);
              urls_blobs.push({preview: url_blob_thubnail, name: element.name});
            });
          }
        });
        this.previews = this.previews.concat(urls_blobs);
      },200)
    },
    getContents() {
      profile_services[this.getWebService()[0]](this.getWebService()[1]).then((response) => {
        for (const property in response.media) {
          this.previews.push(
            {
              preview: (this.network_selected.network === 'instagram') ? response.media[property].basic.preview_url : response.media[property].basic.thumbnail, 
              name: (this.network_selected.network === 'instagram') ? response.media[property].basic.caption : response.media[property].basic.text
            }
          );
        }
        this.previews = this.previews.slice(0, 10);
        this.images_strings = this.previews;
      });
    },
    getWebService() {
      if (this.network_selected.network === 'instagram') return ['getMediaInstagram', this.network_selected.username];
      else if (this.network_selected.network === 'tiktok') return ['getMetricsVideosTiktok', this.network_selected['user_id']];
    },
    getData() {
      if (localStorage.getItem('steps')) {
        this.steps = JSON.parse(localStorage.getItem('steps'));
        this.network_selected = this.steps.network_selected;
        if ((this.network_selected.network === 'instagram' || this.network_selected.network === 'tiktok') && !this.steps.slug) {
          creator_services.getDetailProfiles(this.network_selected.network, this.network_selected.username).then((response) => {
            this.network_selected.user_id = response.user_id;
            this.getContents();
          });
        } else {
          this.getProposal();
        }
      }
    },
    deleteFromDraggable(preview) {
      if (this.steps.slug && preview.uuid) {
        creator_services.deleteElement(this.steps.uuid, preview.uuid, 'image');
      }
      this.files = this.files.filter((item) => item.preview !== preview.preview);
      this.previews = this.previews.filter((item) => item.preview !== preview.preview);
      this.images_strings = this.images_strings.filter((item) => item.preview !== preview.preview);
    },
    getFormatProposal(steps) {
      return {
        work_type: steps.work_type.value,
        content_type: steps.content_type.value,
        user_network_uuid: steps.network_selected.uuid,
        title: steps.title,
        description: steps.description,
        country: steps.location.country.toLowerCase(),
        zipcode: steps.location.cp,
        deals: steps.deals,
      }
    },
    getOrderFormat() {
      const ordered = [];
      this.previews.map((item, index) => {
        ordered.push({name: item.preview.includes('blob:') ? item.name : item.preview, order: index});
      });
      return ordered;
    },
    save() {
      if (!this.steps.uuid) {
        creator_services.createProposal(this.getFormatProposal(this.steps)).then((response) => {
          if (response.uuid) {
            this.steps['uuid'] = response.uuid;
            this.steps['slug'] = response.slug;
            localStorage.setItem('steps', JSON.stringify(this.steps));
            const form_data = new FormData();
            this.images_strings.forEach((images_string) => {
              form_data.append('images_string', images_string.preview);
            });
            this.files.forEach((file) => {
              form_data.append('images_file', file);
            });
            form_data.append('order', JSON.stringify(this.getOrderFormat()));
            creator_services.uploadImagesProposal(this.steps.uuid, form_data)
          }
        });
      }
      this.$router.push({name: 'step_options'});
      this.$loading_steps = true;
    },
    getProposal() {
      this.previews = [];
      this.images_strings = [];
      creator_services.detailProposal(this.steps.slug).then((response) => {
        response.images.map((image) => {
          this.previews.push({preview: utils.getAssetUrl(image.media), uuid: image.uuid})
        })
      })
    },
  },
}
</script>
<style>
.f-11 {
  font-size: 13px;
  color: #b0b0b0;
}
.avatar-preview-sample {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 1em !important;
  margin: 0 1em 0 0;
}
.grip-vertical-icon-preview {
  width: 25px;
  height: 25px; 
  color: #b0b0b0; 
}
.box-previews-draggable {
  cursor:grab;
}
</style>